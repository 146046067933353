.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  margin-bottom: 2.5vmin;
}

.App-header {
  background-color: #1e1e1e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #f1ba53;
  font-size: calc(10px + 1vmin);
  font-weight: 900;
  text-transform: uppercase;
}

.App-link:hover {
  color: #ffd585;
}

p, a {
  transition: ease-in-out all 0.5s;
  font-family: 'Barlow', sans-serif;
}